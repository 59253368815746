import React from 'react';
import { graphql } from 'gatsby';
import Sidebar from '../components/Sidebar.jsx';
import BackroundStartImage from '../components/BackroundStartImage.jsx';
import SEO from '../components/seo';
import dataProjects from '../data/GroupsAndCategories.json';
import MainContent from '../components/MainContent.jsx';
import '../styles/global.css';
import '../styles/reset.css';

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO title="НПО Информ Транспорт" />
      <Sidebar projects={dataProjects} />
      <BackroundStartImage />
      <MainContent
        projects={dataProjects}
        dataProj={data.allMarkdownRemark.nodes}
      />
    </>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          id
        }
        html
      }
    }
  }
`;

export default IndexPage;
