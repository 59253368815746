import React from 'react';
import { Element } from 'react-scroll';

export default function BackroundStartImage() {
  return (
    <Element name={`topImg`}>
      <div className="backgroundStartImg"></div>
      <div style={{ height: 5 }}></div>
    </Element>
  );
}
