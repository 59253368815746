import React from 'react';
import { Link, Events } from 'react-scroll';
import Toggle from './Toggle';
import sidebarStyles from './sidebar.module.css';

export default class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sideBarShow: true, selectedGroup: null };
    this.changeSideBarView = this.changeSideBarView.bind(this);
  }

  changeSideBarView(value) {
    this.setState({ sideBarShow: value });
  }

  componentDidMount() {
    //for view function in another function
    const changeSideBar = this.changeSideBarView;

    const projectsId = this.props.projects.reduce((accum, item) => {
      if (item.categories) {
        accum.push(item.id);
      }
      return accum;
    }, []);
    Events.scrollEvent.register('end', function (to) {
      if (!projectsId.includes(to)) changeSideBar(false);
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('end');
  }

  render() {
    return (
      <>
        <Toggle
          sideBarShow={this.state.sideBarShow}
          changeSideBarView={value => this.setState({ sideBarShow: value })}
        />
        <div
          className={`
            ${sidebarStyles.sidebar}  ${
            this.state.sideBarShow ? sidebarStyles.show : sidebarStyles.hide
          }`}
        >
          <div>
            <nav>
              <Link
                activeClass={`top`}
                id={`top`}
                to={`topImg`}
                spy={true}
                smooth={true}
                duration={800}
                onSetActive={() => {
                  this.setState({ selectedGroup: null });
                }}
              >
                <div className={sidebarStyles.logo}></div>
              </Link>
              <ul>
                {this.props.projects.map(group => {
                  return (
                    <li key={group.id}>
                      <span>
                        <Link
                          activeClass={`active-${group.id}`}
                          to={group.id}
                          spy={true}
                          smooth={true}
                          hashSpy={true}
                          duration={800}
                          ignoreCancelEvents={true}
                          saveHashHistory={true}
                          onSetActive={() => {
                            this.setState({ selectedGroup: group.id });
                          }}
                        >
                          <div className={sidebarStyles.groupSelector}>
                            {group.title}
                          </div>
                        </Link>
                      </span>
                      {group.categories && (
                        <ul
                          className={sidebarStyles.nestedList}
                          style={{
                            display:
                              this.state.selectedGroup === group.id
                                ? 'block'
                                : 'none',
                          }}
                        >
                          {group.categories.map(category => {
                            return (
                              <li key={category.id}>
                                <Link
                                  activeClass={sidebarStyles.active}
                                  to={category.id}
                                  spy={true}
                                  smooth={true}
                                  duration={800}
                                >
                                  <span
                                    className={sidebarStyles.categorySelector}
                                  >
                                    {category.title}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
  }
}
