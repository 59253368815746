import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

import toggleStyle from './toggle.module.css';

export default function Toggle({ sideBarShow, changeSideBarView }) {
  return (
    <button
      className={toggleStyle.toggle}
      onClick={() => {
        changeSideBarView(!sideBarShow);
      }}
    >
      <span className="fa fa-bars" style={{ fontSize: 24 }}></span>
    </button>
  );
}
