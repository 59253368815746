/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//export const onServiceWorkerUpdateReady = () => window.location.reload()

var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;

export const onClientEntry = () => {
  window.onload = () => {
    var hash = window.location.hash;

    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          (typeof safari !== 'undefined' && window['safari'].pushNotification)
      );

    var userAgent = window.navigator.userAgent;

    if (isSafari || userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      if (hash) {
        setTimeout(function () {
          var elem = document.getElementById(hash.substring(1));
          if (elem) {
            scroller.scrollTo(hash.substring(1), {
              duration: 800,
              smooth: true,
            });
          }
        }, 700);
      }
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  };
};
