import React, { useState, useCallback } from 'react';
import { Element } from 'react-scroll';
import Gallery from 'react-photo-gallery';
import { Lightbox } from 'react-modal-image';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { useStaticQuery, graphql } from 'gatsby';

import * as mainContentStyles from './mainContent.module.css';
import Line8 from '../images/contacts/line_8.png';
import Line10 from '../images/contacts/line_10.png';

function MainContent({ dataProj, projects }) {
  const [currentImageName, setCurrentImageName] = useState(null);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo }) => {
    setCurrentImageName(photo.name);
    setViewerIsOpen(true);
  }, []);

  function closeLightbox() {
    setViewerIsOpen(false);
    setCurrentImageName(null);
  }

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={mainContentStyles.content}>
        {projects.map(group => (
          <Element name={group.id} key={group.id}>
            <h2 id={`${group.id}`}>{group.title}</h2>
            <div>
              {!group.categories &&
                (function () {
                  const curCategory = dataProj.find(
                    i => i.frontmatter.id === group.id
                  );
                  return (
                    <Element name={group.id} key={group.id} id={group.id}>
                      {curCategory && (
                        <div
                          className="blog-post-content"
                          dangerouslySetInnerHTML={{
                            __html: curCategory.html,
                          }}
                        />
                      )}
                    </Element>
                  );
                })()}
              {group.photo && (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    // marginBottom: 600,
                  }}
                >
                  {group.photo.map(item => {
                    return (
                      <a
                        key={item.title}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className="item-gallery-partners"
                        style={{
                          backgroundImage: `url(${require('../images/partners/' +
                            item.image)})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          imageRendering: '-webkit-optimize-contrast',
                        }}
                      ></a>
                    );
                  })}
                </div>
              )}
              {group.categories &&
                group.categories.map(category => {
                  const curCategory = dataProj.find(
                    i => i.frontmatter.id === category.id
                  );

                  return (
                    <Element name={category.id} key={category.id} id={group.id}>
                      {curCategory && (
                        <div
                          className="blog-post-content"
                          dangerouslySetInnerHTML={{
                            __html: curCategory.html,
                          }}
                        />
                      )}
                      {category.photo && (
                        <>
                          <Gallery
                            onClick={openLightbox}
                            photos={category.photo.map(x => {
                              return {
                                src: require(`../images/screen-shots-min/${x}.png`),
                                name: x,
                                width: 80,
                                height: 90,
                                className: 'item-gallery',
                              };
                            })}
                          ></Gallery>
                        </>
                      )}
                    </Element>
                  );
                })}
            </div>
            {group.id === 'contacts' && (
              <>
                <div>
                  <div className={mainContentStyles.labelStation}>
                    <img src={Line10} /> Римская
                  </div>
                  <div className={mainContentStyles.labelStation}>
                    <img src={Line8} /> Площадь Ильича
                  </div>
                </div>
                <div className={mainContentStyles.map}>
                  <YMaps>
                    <Map
                      defaultState={{
                        center: [55.741865, 37.67184],
                        zoom: 14,
                      }}
                      width="100%"
                      height={450}
                    >
                      <ZoomControl />
                      <Placemark defaultGeometry={[55.741865, 37.67184]} />
                    </Map>
                  </YMaps>
                </div>
                <div className={mainContentStyles.mt20}>
                  <h4 className="font-bold">Как написать:</h4>
                  <p>
                    109544, г. Москва, ул. Большая Андроньевская, д. 17, ком.
                    435
                  </p>
                  <a
                    className="link_a"
                    style={{
                      color: '#ffff',
                    }}
                    href="tel:+7 495 020-23-75"
                  >
                    +7 495 020-23-75
                  </a>
                  <div className={mainContentStyles.mt12}>
                    <a
                      href="mailto:info@trans-ip.ru"
                      className="link_a"
                      style={{
                        color: '#ffff',
                      }}
                    >
                      info@trans-ip.ru
                    </a>
                  </div>
                </div>
              </>
            )}
            {group.id === 'software' && (
              <>
                <div style={{ marginBottom: 13 }}>
                  {`По вопросам приобретения или для уточнения возможностей
                  Бортового ПО СВЛ ТР обращайтесь по электронной почте `}
                  <a
                    href="mailto:info@trans-ip.ru"
                    className="link_a"
                    style={{
                      color: '#ffff',
                    }}
                  >
                    info@trans-ip.ru
                  </a>
                  {` или по телефону `}
                  <a
                    className="link_a"
                    style={{
                      color: '#ffff',
                    }}
                    href="tel:+7 (495) 020-23-75"
                  >
                    +7 (495) 020-23-75
                  </a>
                </div>
                <div>
                  {`Краткое описание Бортового ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x =>
                          x.node.name == 'Краткое описание Бортового ПО СВЛ ТР'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>
                <div>
                  {`Информация, необходимая для эксплуатации Бортового ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x =>
                          x.node.name ==
                          'Информация, необходимая для эксплуатации Бортового ПО СВЛ ТР'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>
                <div>
                  {`Свидетельство на Бортовое ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x =>
                          x.node.name ==
                          'Свидетельство и изменение в свидетельство о госрегистрации ПО'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>
                {(function () {
                  const curCategory = dataProj.find(
                    i => i.frontmatter.id === 'software-svl'
                  );
                  return (
                    <div
                      className="blog-post-content"
                      style={{ marginTop: 50 }}
                      dangerouslySetInnerHTML={{
                        __html: curCategory.html,
                      }}
                    ></div>
                  );
                })()}
                <div style={{ marginBottom: 13 }}>
                  {`По вопросам приобретения или для уточнения возможностей
                  ПО СВЛ ТР обращайтесь по электронной почте `}
                  <a
                    href="mailto:info@trans-ip.ru"
                    className="link_a"
                    style={{
                      color: '#ffff',
                    }}
                  >
                    info@trans-ip.ru
                  </a>
                  {` или по телефону `}
                  <a
                    className="link_a"
                    style={{
                      color: '#ffff',
                    }}
                    href="tel:+7 (495) 020-23-75"
                  >
                    +7 (495) 020-23-75
                  </a>
                </div>
                <div>
                  {`Краткое описание ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x => x.node.name == 'Краткое описание ПО СВЛ ТР'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>
                <div>
                  {`Информация, необходимая для эксплуатации ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x =>
                          x.node.name ==
                          'Информация, необходимая для эксплуатации ПО СВЛ ТР'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>
                <div>
                  {`Свидетельство на ПО СВЛ ТР (`}
                  <a
                    href={
                      data.allFile.edges.find(
                        x => x.node.name == 'Свидетельство на ПО СВЛ ТР'
                      ).node.publicURL
                    }
                    download
                  >
                    скачать
                  </a>
                  )
                </div>

                <div style={{ marginBottom: 265 }}></div>
              </>
            )}
          </Element>
        ))}
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/screen-shots/${currentImageName}.png`)}
                onClose={closeLightbox}
              />
            </>
          );
        })()}
    </>
  );
}

export default MainContent;
